import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages/Home.vue"),
  },
  {
    path: "/our-beloved-thai-icons",
    name: "Our belived Thai icons",
    component: () => import("@/pages/OurBelovedThaiIcons.vue"),
  },
  {
    path: "/gourmet",
    name: "Gourmet",
    component: () => import("@/pages/Gourmet.vue"),
  },
  {
    path: "/elephant",
    name: "Elephant",
    component: () => import("@/pages/Elephant.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
